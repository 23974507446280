import React from 'react';
import './trangchu.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default function Trangchu() {

    return (
        <>
            <div className='container-fluid main'>
                <div className='row'>
                    <div className='col-sm-3 logo-main' >  </div>
                </div>
                <div className='row'>
                    <div className='col-sm-3 link-main ' > <h1>Tham Gia Nhóm Telegram Tại Đây</h1>  </div>
                </div>
            </div>
        </>
    )
}