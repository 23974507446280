// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  display: "flex";
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/routes/systemtrandau.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,uBAAA;AACJ","sourcesContent":[".pagination {\r\n    display: 'flex';\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
