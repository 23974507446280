import '../containers/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Trangchu from '../routes/Trangchu';
import Dangnhapne from '../routes/Dangnhapne';
import Systemtrandau from '../routes/Systemtrandau';
import TestXemVideo from '../routes/TestXemVideo';
import TestXemVideo2 from '../routes/TestXemVideo2';
import Kiemtraauth from '../components/kiemtraAuth';

function App() {
  return (

    /*  <Routes>
       <Route path="/" element={<Trangchu />} />
       <Route path="/dangnhapne" element={<Dangnhapne />} />
       <Route path="/systemtrandau" element={<Systemtrandau />} />
       <Route path="/video/:league/:teams" element={<TestXemVideo />} />
       <Route path="/video2/:league/:teams" element={<TestXemVideo2 />} />
     </Routes> */

    <Router>
      <Routes>
        <Route path="/" element={<Trangchu />} />
        <Route path="/dangnhapne" element={<Dangnhapne />} />
        <Route element={<Kiemtraauth />}>
          <Route path="/systemtrandau" element={<Systemtrandau />} />
          <Route path="/video/:league/:teams" element={<TestXemVideo />} />
          <Route path="/video2/:league/:teams" element={<TestXemVideo2 />} />
        </Route>
      </Routes>
    </Router>


  );
}

export default App;
