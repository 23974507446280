import React, { useEffect, useState } from "react";
import axios from 'axios';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';

import './systemtrandau.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default function Systemtrandau() {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState('startTime');
    const [sortOrder, setSortOrder] = useState('asc');
    const [selectedLeague, setSelectedLeague] = useState('all');
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.thailandsupply.com/data-trandauAPI');
                setData(response.data.dataTable);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const formatDate = (dateString) => {
        return moment(dateString).tz('Asia/Bangkok').format('DD-MM-YYYY HH:mm');
    };

    useEffect(() => {
        for (let i = 0; i < data.length; i++) {
            const inputFlv = document.getElementById('myInputFlv' + i);
            const inputM3u8 = document.getElementById('myInputM3u8' + i);
            const inputH5 = document.getElementById('myInputH5' + i);

            if (inputFlv) inputFlv.addEventListener('click', () => copyToClipboard(inputFlv));
            if (inputM3u8) inputM3u8.addEventListener('click', () => copyToClipboard(inputM3u8));
            if (inputH5) inputH5.addEventListener('click', () => copyToClipboard(inputH5));
        }

        // Cleanup function to remove event listeners when the component unmounts or data changes
        return () => {
            for (let i = 0; i < data.length; i++) {
                const inputFlv = document.getElementById('myInputFlv' + i);
                const inputM3u8 = document.getElementById('myInputM3u8' + i);
                const inputH5 = document.getElementById('myInputH5' + i);

                if (inputFlv) inputFlv.removeEventListener('click', () => copyToClipboard(inputFlv));
                if (inputM3u8) inputM3u8.removeEventListener('click', () => copyToClipboard(inputM3u8));
                if (inputH5) inputH5.removeEventListener('click', () => copyToClipboard(inputH5));
            }
        };
    }, [data]);

    const copyToClipboard = (input) => {
        input.select();
        input.setSelectionRange(0, 99999);

        try {
            document.execCommand('copy');
            alert('Đã Copy Thành Công ');
        } catch (err) {
            alert('Không Copy Được');
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSortFieldChange = (event) => {
        setSortField(event.target.value);
        setSortOrder('asc'); // Reset sortOrder to 'asc' whenever sortField changes
    };

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
    };

    const handleLeagueChange = (event) => {
        setSelectedLeague(event.target.value);
    };

    const getUniqueLeagues = (data) => {
        const leagues = data.map(match => match.league_title);
        return ['all', ...new Set(leagues)];
    };

    const filteredData = selectedLeague === 'all' ? data : data.filter(match => match.league_title === selectedLeague);

    const sortedData = filteredData.slice().sort((a, b) => {
        const aValue = sortField === 'startTime' ? new Date(a[sortField]) : a[sortField];
        const bValue = sortField === 'startTime' ? new Date(b[sortField]) : b[sortField];

        if (aValue < bValue) {
            return sortOrder === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
    });


    return (
        <>
            <div className="container contain-main">
                <h2>List Trận Đấu</h2>
                <div className="row">
                    <div className="col-sm-4">
                        <label htmlFor="sortField">Sort by:</label>
                        <select id="sortField" value={sortField} onChange={handleSortFieldChange} className="form-select">
                            <option value="league_title">League</option>
                            <option value="startTime">Time</option>
                        </select>
                    </div>
                    {sortField === 'startTime' && (
                        <div className="col-sm-4">
                            <label htmlFor="sortOrder">Order:</label>
                            <select id="sortOrder" value={sortOrder} onChange={handleSortOrderChange} className="form-select">
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </div>
                    )}
                    {sortField === 'league_title' && (
                        <div className="col-sm-4">
                            <label htmlFor="leagueSelect">Filter by League:</label>
                            <select id="leagueSelect" value={selectedLeague} onChange={handleLeagueChange} className="form-select">
                                {getUniqueLeagues(data).map((league, index) => (
                                    <option key={index} value={league}>{league}</option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
                <table className="row table">
                    <thead>
                        <tr className="row">
                            <th className="col-sm-3 ">Trận Đấu</th>
                            <th className="col-sm-2">Mùa Giải</th>
                            <th className="col-sm-2">Thời Gian</th>
                            <th className="col-sm-5">Link Video</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((match, index) => {
                            //{data.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((match, index) => {
                            // {data.map((match, index) => {
                            const videoPageURL = `/video/${encodeURIComponent(match.league_title)}/${encodeURIComponent(match.localteam_title)}-vs-${encodeURIComponent(match.visitorteam_title)}`;
                            const handleClick = () => {
                                localStorage.setItem('videoUrl', match.m3u8);
                                window.open(`/video2/${encodeURIComponent(match.league_title)}/${encodeURIComponent(match.localteam_title)}-vs-${encodeURIComponent(match.visitorteam_title)}`, '_blank');
                            };
                            return (
                                <tr key={match.matchId} className="row">
                                    <td className="col-sm-3">

                                        <div className="row">
                                            <div className="col-sm-6">{match.localteam_title}</div>
                                            <div className="col-sm-6">{match.visitorteam_title}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6"><img className="img-fluid" src={match.localteam_logo} alt="Đội Chủ Nhà" width={'80px'} /></div>
                                            <div className="col-sm-6"><img className="img-fluid" src={match.visitorteam_logo} alt="Đội Khách" width={'80px'} /></div>
                                        </div>
                                    </td>
                                    <td className="col-sm-2">{match.league_title}</td>
                                    <td className="col-sm-2">{formatDate(match.startTime)}</td>
                                    <td className="col-sm-5">
                                        <p>FLV : <input type="text" value={match.flv} id={`myInputFlv${index}`} className="form-control" readOnly /></p>
                                        <p>M3U8 :<Link
                                            to={videoPageURL}
                                            state={{ m3u8: match.m3u8 }}
                                            /* target="_blank" rel="noopener noreferrer" */ >
                                            <button className="btn btn-primary">Xem Video Link</button></Link>
                                            <button className="btn btn-primary" onClick={handleClick}>Xem Video Local Storage</button>
                                            <input type="text" value={match.m3u8} id={`myInputM3u8${index}`} className="form-control" readOnly /></p>
                                        <p>H5 :<input type="text" value={match.h5} id={`myInputH5${index}`} className="form-control" readOnly /></p>

                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="row">
                    <div className="col-sm-12 pagination" >
                        <Pagination count={Math.ceil(data.length / itemsPerPage)} page={page} onChange={handlePageChange} color="primary" variant="outlined" shape="rounded" />
                    </div>
                </div>
            </div><br /><br /><br />
        </>
    )
}