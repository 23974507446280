/* import React, { useEffect, useState } from "react"; */
import axios from 'axios';

const xulyDangnhap = async (username, password) => {
    try {
        return await axios.post('https://api.thailandsupply.com/loginAPI', { username, password });
    } catch (e) {
        throw (e);
    }


};

export {
    xulyDangnhap
};