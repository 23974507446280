import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_FIELD, TOGGLE_SHOW_PASSWORD, SET_ERROR } from '../actions/actionType';

export const loginSuccess = (userToken, errMess) => ({
    type: LOGIN_SUCCESS,
    payload: { userToken, errMess },
});

export const loginFail = (error, errMess) => ({
    type: LOGIN_FAIL,
    payload: { error, errMess },
});

export const logout = () => ({
    type: LOGOUT,
});

export const setField = (field, value) => ({
    type: SET_FIELD,
    field: field,
    value: value,
});

export const toggleShowPassword = () => ({
    type: TOGGLE_SHOW_PASSWORD,
});

export const setError = (error) => ({
    type: SET_ERROR,
    error: error,
});