import React, { useEffect, useState } from "react";
import ReactHlsPlayer from 'react-hls-player';

export default function TestXemVideo2() {
    const [m3u8, setM3u8] = useState('');

    useEffect(() => {
        const storedUrl = localStorage.getItem('videoUrl');
        setM3u8(storedUrl);
    }, []);


    /* console.log(m3u8); */
    /* console.log(setM3u8); */


    return (
        <>
            <meta name="referrer" content="never" ></meta>
            <h1>Video Test</h1>
            <div>
                {m3u8 ? (
                    <ReactHlsPlayer
                        src={m3u8}
                        autoPlay={true}
                        controls={true}
                        width="40%"
                        height="auto"
                    />
                ) : (
                    <p>Không có liên kết video.</p>
                )}
            </div>
        </>
    );
}
