import React, { /* useState, */ useReducer } from 'react';
import './dangnhapne.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import * as loginservice from '../services/loginservice';

import { loginSuccess, loginFail, setField, toggleShowPassword, setError } from '../store/actions/userAction';
import authReducer from '../store/reducers/userReducer';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

export default function Dangnhapne() {
    const tongState = {
        username: '',
        password: '',
        showpss: false,
        errMess: '',
        isLoggedIn: false,
        userToken: null,
        error: null,
    };

    const navigate = useNavigate(); // Initialize useNavigate
    // Sử dụng State - useState khi test lần đầu, chưa phức tạp
    /* const [state, setState] = useState(tongState); */

    const [state, dispatch] = useReducer(authReducer, tongState);

    const xulythaydoi = (event) => {
        const { name, value } = event.target;
        //sử dụng state
        /* setState((prevState) => ({
            ...prevState,
            [name]: value,
        })); */

        //sử dụng reducer
        dispatch(setField(name, value));
    };

    const xulydangnhap = async () => {
        if (!state.username) {
            /* console.log('Username is required'); */
            // Sử dụng state
            /* setState((prevState) => ({
                ...prevState,
                errMess: 'Username is required'
            })); */

            //sử dụng reducer
            dispatch(setError('Username is required'));
            return;
        } else if (!state.password) {
            /* console.log('Password is required'); */
            //sử dụng reducer
            /* setState((prevState) => ({
                ...prevState,
                errMess: 'Password is required'
            }));*/

            //sử dụng reducers
            dispatch(setError('Password is required'));
            return;
        }
        try {
            const dataDangnhap = await loginservice.xulyDangnhap(state.username, state.password);
            //sử dụng state
            /* setState((prevState) => ({
                ...prevState,
                errMess: dataDangnhap.data.user.errMess || '',
            })); */

            //sử dụng reducers
            if (dataDangnhap.data.user.errCode === 0) {
                localStorage.setItem('userToken', JSON.stringify(dataDangnhap.data.user.token));
                dispatch(loginSuccess(dataDangnhap.data.user.token, dataDangnhap.data.user.errMess));
                navigate('/systemtrandau'); // Redirect to /systemtrandau after successful login
            } else {
                dispatch(loginFail(state.error, dataDangnhap.data.user.errMess));
            }
        } catch (e) {
            console.error('Login failed', e);
            //sử dụng state
            /* setState((prevState) => ({
                ...prevState,
                errMess: 'Login failed',
            })); */

            //sử dụng reducer
            dispatch(loginFail('Login failed'));
        }


    };

    const xulyanhienpss = () => {
        // sử dụng state
        /*  setState((prevState) => ({
            ...prevState,
            showpss: !prevState.showpss,
        })); */

        //sử dụng reducers
        dispatch(toggleShowPassword());
    };

    return (
        <>
            <div className='login-background'>
                <div className='login-form'>
                    <div className='login-content row'>
                        <div className='col-12 text-login'>Login</div>
                        <div className='col-12 form-group login-input'>
                            <label>Username</label>
                            <input
                                id='username'
                                name='username'
                                type='text'
                                className='form-control'
                                placeholder='Nhập Tên Đăng Nhập'
                                value={state.username}
                                onChange={xulythaydoi}
                            ></input>
                        </div>
                        <div className='col-12 form-group login-input'>
                            <label>Password</label>
                            <div className='password-input'>
                                <input
                                    id='password'
                                    name='password'
                                    type={state.showpss ? 'text' : 'password'}
                                    className='form-control'
                                    placeholder='Nhập Mật Khẩu'
                                    value={state.password}
                                    onChange={xulythaydoi}
                                ></input>
                                <span className='pss-icon' onClick={xulyanhienpss}><FontAwesomeIcon icon={state.showpss ? faEye : faEyeSlash} /></span>

                            </div>

                        </div>
                        <div className='col-12 error-mess' >
                            {state.errMess}
                        </div>
                        <div className='col-12'>
                            <button className='btn-login' onClick={xulydangnhap}>Đăng Nhập</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}