
import React from "react";
import ReactHlsPlayer from 'react-hls-player';
/* import { useParams } from 'react-router-dom'; */
import { useLocation } from "react-router-dom";

export default function TestXemVideo() {
    const location = useLocation();
    const m3u8 = location.state?.m3u8;  // Lấy giá trị từ state, đảm bảo không lỗi nếu state là undefined

    console.log("State in TestXemVideo:", location.state); // Debug: Kiểm tra state

    return (
        <>
            <meta name="referrer" content="never"></meta>
            <h1>Video Test</h1>
            <div>
                {m3u8 ? (
                    <ReactHlsPlayer
                        src={m3u8}
                        autoPlay={true}
                        controls={true}
                        width="40%"
                        height="auto"
                    />
                ) : (
                    <p>Không có liên kết video.</p>
                )}
            </div>
        </>
    )
}