import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_FIELD, TOGGLE_SHOW_PASSWORD, SET_ERROR } from '../actions/actionType';

const tongState = {
    username: '',
    password: '',
    showpss: false,
    errMess: '',
    isLoggedIn: false,
    userToken: null,
    error: null,
};

const authReducer = (state = tongState, action) => {
    switch (action.type) {
        case SET_FIELD:
            return {
                ...state,
                [action.field]: action.value,
            };
        case TOGGLE_SHOW_PASSWORD:
            return {
                ...state,
                showpss: !state.showpss,
            };
        case SET_ERROR:
            return {
                ...state,
                errMess: action.error,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                userToken: action.payload,
                error: null,
                errMess: action.payload.errMess,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                userToken: null,
                error: action.payload.error,  // Use error from payload
                errMess: action.payload.errMess,  // Use errMess from payload
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                userToken: null,
                error: null,
            };
        default:
            return state;
    }
};

export default authReducer;